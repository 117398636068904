import React from "react";
import {leaves}  from './Leaves.module.css'
const Leaves = () => {
  return (
    <div style={{zIndex: -9}} id={leaves}>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};

export default Leaves;
