import * as React from "react";
import "./index.css";
import Leaves from "../components/Leaves";
import {
  Hero,
  Header,
  About,
  Stats,
  Projects,
  Team,
  Footer,
} from "../layout/landing/index";
import { DataProvider } from "../utils";
import { Helmet } from "react-helmet";


const IndexPage = () => {
  React.useEffect(() => {
    console.log(
      "🤔 What are you curious about? How about you join us: https://neocedrus.com/apply"
    );
  }, []);
  let url = "https://neocedrus.com" 
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>neoCedrus - Student-Led Software Development Unit</title>
        <meta name="title" content="neoCedrus" />
        <meta name="description" content="Student-led Sotware Development Unit at Al Akhawayn University" />
        <link rel="canonical" href={url} />
        <meta name="image" content="https://cdn.neocedre.com/neocedrus-landing/assets/ogImg.png" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"neoCedrus"} />
        <meta property="og:description" content="Student-led Sotware Development Unit at Al Akhawayn University" />
        <meta property="og:image" content="https://cdn.neocedre.com/neocedrus-landing/assets/ogImg.png" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="neoCedrus" />

        <meta name="twitter:card" content="summary" />
      </Helmet>
      <DataProvider>
        <Leaves />
        <Header />
        <div className="md:px-24">
          <Hero />
          <About />
          <Stats />
          <Projects />
          <Team />
          {/* <BlogSection /> */}
        </div>
        <Footer />
      </DataProvider>
    </div>
  );
};
export default IndexPage;
